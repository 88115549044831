<div *ngIf="state === 'loading'" class="container loading-container d-flex flex-center">
  <loader [loading]="state === 'loading'" size="md" alt="loading" id="loading"></loader>
</div>

<div id="product-details" *ngIf="!isOrderNow && product && !productInvalid" class="container">
  <div class="row">
    <app-breadcrumbs
      *ngIf="!isDiscoverabilityFunnelEnabled"
      [categoryHierachy]="categoryHierarchy"
    ></app-breadcrumbs>
  </div>
  <p *ngIf="closingMessage" class="market-unavailability-message">
    {{ closingMessage }}
  </p>

  <app-product-details-preview
    *ngIf="isNewProductDetailsEnabled"
    [currentProduct]="product"
    [currentVariantGroup]="variantGroup"
    [productIsCataloged]="isCataloged"
    (addToCart)="addToCart()"
    (orderNow)="orderNow($event)"
    (toggleIsCataloged)="onCatalogProduct()"
    (variantChange)="goToVariantById($event)"
    (changeDisplayedAdditionalInfo)="onAdditionalInfoChange($event)"
    (downloadedImages)="onDownloadedImages()"
    (downloadedVideos)="onDownloadedVideos()"
    (reviewProduct)="reviewProduct()"
    [currency]="currency"
    (openBulkRequestDialog)="displayRules()"
    [bulkPreOrderButtonIsVisible]="bulkPreOrderButtonIsVisible"
    [productIsOrderable]="
      preorderV2FeatureEnabled ? product.isAvailableToSeller : productIsOrderable
    "
    [flashSaleTitle]="flashSaleTitle"
    [isPriceRangeEnabled]="isPriceRangeEnabled"
    [userHasStore]="userHasStore"
    [storeProvider]="storeProvider"
    [productAddedToStore]="productAddedToStore"
    [isDiscountAsSecondProduct]="
      shouldShowDiscountAsSecondProduct ? product?.discountedAsSecondInPlacement?.enabled : false
    "
    [userHasStockAvailability]="userHasStockAvailability"
    [showStockAvailability]="showStockAvailability"
    [stockAvailabilityStatus]="stockAvailabilityStatus"
    [stockAvailabilityRunRate]="stockAvailabilityRunRate"
    [skuAnalyticsEnabled]="skuAnalyticsEnabled"
    [skuAnalytics]="skuAnalytics"
    (addProductToStore)="onAddProductToStore()"
  ></app-product-details-preview>

  <div class="best-seller col-12" *ngIf="!hasUpSellableVariants || !upsellableProductsFlag">
    <div class="best-seller-title">{{ 'PRODUCTS_DETAIL_PAGE.BEST_SELLER' | translate }}</div>
    <div class="best-seller-category-bar d-flex">
      <button
        *ngFor="let category of bestSellerCategories"
        appHoverStyle
        class="best-seller-category-button"
        [ngClass]="{
          'best-seller-category-selected':
            bestSellerCarouselData.categoryName === category.name.arabicName
        }"
        (click)="onSelectBestSellerCategory(category.name.arabicName)"
      >
        {{ isEnglishLanguage ? category.name.englishName : category.name.arabicName }}
      </button>
    </div>
    <section class="best-seller-carousel d-flex flex-column" *ngIf="!bestSellersLoading">
      <app-products-carousel
        [isCatalogDataLoaded]="isCatalogDataLoaded"
        [carouselData]="bestSellerCarouselData"
      ></app-products-carousel>
    </section>
    <section
      class="best-seller d-flex flex-column justify-content-center best-seller-loader"
      *ngIf="bestSellersLoading"
    >
      <loader
        [loading]="bestSellersLoading"
        size="sm"
        alt="bestSellersLoading"
        id="bestSellersLoading"
      ></loader>
    </section>
  </div>
  <div class="best-seller col-12" *ngIf="hasUpSellableVariants && upsellableProductsFlag">
    <div class="best-seller-title"></div>
    <section class="best-seller-carousel d-flex flex-column" *ngIf="!bestSellersLoading">
      <app-products-carousel
        [isCatalogDataLoaded]="isCatalogDataLoaded"
        [carouselData]="upSellableVariantsCarouselData"
      ></app-products-carousel>
    </section>
  </div>
</div>

<div
  *ngIf="productInvalid"
  class="nonexistent-product d-flex flex-column justify-content-center align-items-center"
>
  <p>{{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_DOES_NOT_EXIST' | translate }}</p>
  <button class="back-to-main-button" [routerLink]="['/products-v2']" appHoverStyle>
    {{ 'PRODUCTS_DETAIL_PAGE.GO_TO_PRODUCT_LIST' | translate }}
  </button>
</div>

<div class="order-now">
  <app-order
    *ngIf="product && isOrderNow"
    [isOrderNow]="false"
    [orderFromProduct]="true"
    [products]="selectedProducts"
    (submitted)="orderSubmitted($event)"
    (back)="reload()"
  ></app-order>
</div>
